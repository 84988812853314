import React, { useState } from 'react';
import { Modal, Box, Typography, Button, TextField } from '@mui/material';
import { DatePicker, TimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

interface PickerFinishDateModalProps {
    open: boolean;
    onClose: () => void;
    onSave: (date: Date | null) => void;
    initialDate: Date | null;
}

const PickerFinishDateModal: React.FC<PickerFinishDateModalProps> = ({ open, onClose, onSave, initialDate }) => {
    const [selectedDate, setSelectedDate] = useState<Date | null>(initialDate);

    const handleSave = () => {
        onSave(selectedDate);
        onClose();
    };

    const handleDateChange = (newDate: Date | null) => {
        if (newDate) {
            const updatedDate = new Date(selectedDate || newDate);
            updatedDate.setFullYear(newDate.getFullYear(), newDate.getMonth(), newDate.getDate());
            setSelectedDate(updatedDate);
        }
    };

    const handleTimeChange = (newTime: Date | null) => {
        if (newTime) {
            const updatedDate = new Date(selectedDate || newTime);
            updatedDate.setHours(newTime.getHours(), newTime.getMinutes(), newTime.getSeconds());
            setSelectedDate(updatedDate);
        }
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
                <Typography variant="h6" component="h2">
                    Seleccionar Fecha y Hora de Finalización del Picking
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <div>
                        <DatePicker
                            label="Fecha de Finalización"
                            value={selectedDate}
                            onChange={handleDateChange}
                        />
                    </div>
                    <div className="mt-4">
                        <TimePicker
                            label="Hora de Finalización"
                            value={selectedDate}
                            onChange={handleTimeChange}
                        />
                    </div>
                </LocalizationProvider>
                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                    <Button onClick={onClose} sx={{ mr: 2 }}>Cancelar</Button>
                    <Button variant="contained" disabled={!selectedDate} onClick={handleSave}>Guardar</Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default PickerFinishDateModal;
