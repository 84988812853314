import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../hooks/storeHooks';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Card, MenuItem, Button, IconButton } from '@mui/material';
import { fetchOrders, updateOrder } from '../../../../services/orderService';
import { fetchPickers } from '../../../../services/pickerService';
import EditableSelectLabel from '../../../EditableSelectLabel';
import handleGenerateOrdersExcel from '../../../../utils/createOrderWithPickersExcel';
import PickerFinishDateModal from './PickerFinishDateModal';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

const OrderWithPickersView: React.FC = () => {
    const { isLoading, orders } = useAppSelector((state) => state.orderReducer);
    const [pickers, setPickers] = useState<Picker[]>([]);
    const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            await fetchOrders({ picking: true });
            const pickersData = await fetchPickers();
            setPickers(pickersData);
        };

        fetchData();
    }, []);

    const handlePickerChange = async (orderId: string, pickerId: string) => {
        await updateOrder({ picker: pickerId, pickerAssignDate: new Date() }, orderId);
    };

    const handleDownloadExcel = () => {
        const ordersData = orders.map(order => ({
            ...order,
            pickerName: pickers.find(p => p._id === order.picker)?.name || '',
        }));
        handleGenerateOrdersExcel(ordersData);
    };

    const handleOpenModal = (order: Order) => {
        setSelectedOrder(order);
        setModalOpen(true);
    };

    const handleSaveDate = async (date: Date | null) => {
        if (selectedOrder) {
            await updateOrder({ pickerFinishDate: date! }, selectedOrder._id);
        }
    };

    const columns: GridColDef[] = [
        { field: 'dateInMilliseconds', headerName: 'ID Red Pine', width: 150 },
        { field: 'laudusId', headerName: 'Laudus ID', width: 150 },
        {
            field: 'customer', headerName: 'Cliente', width: 150, valueGetter(params) {
                return params.row.customer.name;
            },
        },
        {
            field: 'date', headerName: 'Fecha', width: 150,
            valueGetter(params) {
                return new Date(params.row.date).toLocaleDateString();
            },
        },
        { field: 'discountedNetTotal', headerName: 'Total Neto', width: 150 },
        { field: 'routeName', headerName: 'Ruta', width: 150 },
        {
            field: 'picker', headerName: 'Picker', width: 300, renderCell: (params) => {
                const pickerName = pickers.find(p => p._id === params.row.picker)?.name;
                return (
                    <EditableSelectLabel
                        onSubmit={(value) => handlePickerChange(params.id as string, value)}
                        value={params.value}
                        id="picker"
                        label="Picker"
                        labelValue={pickerName}
                        renderValue={(value) => {
                            const picker = pickers.find(p => p._id === value);
                            return picker ? picker.name : '';
                        }}
                    >
                        {pickers.map((picker) => (
                            <MenuItem key={picker._id} value={picker._id}>
                                {picker.name}
                            </MenuItem>
                        ))}
                    </EditableSelectLabel>
                );
            }
        },
        {
            field: 'pickerAssignDate', headerName: 'Fecha de Asignación', width: 200, valueGetter: (params) => {
                return params.row.pickerAssignDate ? new Date(params.row.pickerAssignDate).toLocaleString() : 'No asignada';
            }
        },
        {
            field: 'pickerFinishDate', headerName: 'Fecha de Finalización', width: 300, renderCell: (params) => {
                const finishDate = params.row.pickerFinishDate ? new Date(params.row.pickerFinishDate).toLocaleString() : 'No asignada';
                return (
                    <div className="flex items-center">
                        <span>{finishDate}</span>
                        <IconButton onClick={() => handleOpenModal(params.row)} disabled={!params.row.picker}>
                            <CalendarTodayIcon />
                        </IconButton>
                    </div>
                );
            }
        },
    ];

    return (
        <Card>
            <div className='mb-3'>
                <Button variant="contained" color="primary" onClick={handleDownloadExcel}>
                    Descargar en Excel
                </Button>
            </div>
            <DataGrid
                autoHeight
                rows={orders}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                getRowId={(row) => row._id}
                loading={isLoading}
            />
            {selectedOrder && (
                <PickerFinishDateModal
                    open={modalOpen}
                    onClose={() => setModalOpen(false)}
                    onSave={handleSaveDate}
                    initialDate={selectedOrder.pickerFinishDate || null}
                />
            )}
        </Card>
    );
};

export default OrderWithPickersView;
