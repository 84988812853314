import { DataGrid, GridRenderCellParams, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarFilterButton, esES } from '@mui/x-data-grid';
import React, { useEffect, useRef, useState } from 'react';
import { useAppSelector } from '../../../../hooks/storeHooks';
import Icon from '@mdi/react';
import { mdiCircleSlice1 } from '@mdi/js';
import { Button, Card } from '@mui/material';
import { fetchOrdersByMonthAndDay } from '../../../../services/orderService';

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
        </GridToolbarContainer>
    );
}

export const OrdersReportView = () => {
    const [sellersReports, setSellersReports] = useState<SellerOrdersByMonthAndDay[]>([]);
    const { sellers, isLoading } = useAppSelector((s: any) => s.sellerReducer);
    const { sellerOrdersByMonthAndDay, isLoadingByMonthAndDay } = useAppSelector((s) => s.orderReducer);
    const effectRan = useRef(false);

    const [totalSales, setTotalSales] = useState({
        name: "Total",
        monthlyTotal: 0,
        dailyTotal: 0,
    });

    useEffect(() => {
        if (effectRan.current === false) {
            fetchOrdersByMonthAndDay();
            return () => {
                effectRan.current = true;
            };
        }
    }, []);

    useEffect(() => {
        if (sellers.length && sellerOrdersByMonthAndDay.length) {
            const sellersWithSales = sellerOrdersByMonthAndDay.map((order) => {
                const seller = sellers.find((s: any) => s._id === order._id);
                return {
                    ...order,
                    name: seller ? seller.name : 'Unknown'
                };
            });
            setSellersReports(sellersWithSales);
        }
    }, [sellers, sellerOrdersByMonthAndDay]);

    useEffect(() => {
        const totalMonthlySales = sellersReports.reduce((acc, order) => acc + order.monthlyTotal, 0);
        const totalDailySales = sellersReports.reduce((acc, order) => acc + order.dailyTotal, 0);
        setTotalSales({
            monthlyTotal: totalMonthlySales,
            dailyTotal: totalDailySales,
            name: "Total"
        });
    }, [sellersReports]);

    const columns = [
        { field: 'name', headerName: 'Nombre Vendedor', width: 150 },
        {
            field: 'monthlyTotal', headerName: 'Ventas del mes', width: 150,
            renderCell(params: GridRenderCellParams) {
                return params.row.monthlyTotal.toLocaleString("es-CL", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                });
            }
        },
        {
            field: 'dailyTotal', headerName: 'Ventas del día', width: 150,
            renderCell(params: GridRenderCellParams) {
                return params.row.dailyTotal.toLocaleString("es-CL", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                });
            }
        },
    ];

    return (
        <div className="h-full overflow-auto">
            {isLoading || isLoadingByMonthAndDay ? (
                <div className="flex justify-center w-full col-span-full">
                    <Icon spin path={mdiCircleSlice1} className="w-7"></Icon>
                </div>
            ) : (
                <Card>
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{ margin: 2 }}
                        onClick={() => {
                            fetchOrdersByMonthAndDay();
                        }}
                    >
                        Actualizar
                    </Button>
                    <DataGrid
                        sx={{ padding: 2 }}
                        autoHeight={true}
                        pageSize={1}
                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        disableColumnMenu
                        columns={columns}
                        rows={[totalSales]}
                        getRowId={(row) => row.name}
                        components={{ Toolbar: CustomToolbar }}
                    />
                    <DataGrid
                        sx={{ padding: 2 }}
                        autoHeight={true}
                        pageSize={20}
                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        disableColumnMenu
                        columns={columns}
                        rows={sellersReports}
                        getRowId={(row) => row._id}
                        components={{ Toolbar: CustomToolbar }}
                    />
                </Card>
            )}
        </div>
    );
};
