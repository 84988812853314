// src/reducers/OrderReducer.ts

interface State {
  orders: Order[];
  sellerOrdersByMonthAndDay: SellerOrdersByMonthAndDay[]; // Nuevo estado
  isLoading: boolean;
  isLoadingByMonthAndDay: boolean; // Nuevo estado
  error: string;
}

const initialState: State = {
  orders: [],
  sellerOrdersByMonthAndDay: [], // Inicialización del nuevo estado
  isLoading: false,
  isLoadingByMonthAndDay: false, // Inicialización del nuevo estado
  error: "",
};

export enum OrderActions {
  RequestOrders = "REQUEST_ORDERS",
  ReceiveOrders = "RECEIVE_ORDERS",
  SetError = "SET_ERROR",
  AddOrder = "ADD_ORDER",
  RequestOrdersByMonthAndDay = "REQUEST_ORDERS_BY_MONTH_AND_DAY", // Nueva acción
  ReceiveOrdersByMonthAndDay = "RECEIVE_ORDERS_BY_MONTH_AND_DAY", // Nueva acción
}

const reducer = (
  state: State = initialState,
  { type, payload }: { type: OrderActions; payload: any }
): State => {
  switch (type) {
    case OrderActions.RequestOrders:
      return { ...state, isLoading: true };
    case OrderActions.ReceiveOrders:
      return { ...state, orders: payload, isLoading: false };
    case OrderActions.SetError:
      return { ...state, error: payload, isLoading: false };
    case OrderActions.AddOrder:
      const updatedOrderIndex = state.orders.findIndex(o => o._id === payload._id);
      if (updatedOrderIndex !== -1) {
        const updatedOrders = [...state.orders];
        updatedOrders[updatedOrderIndex] = payload;
        return {
          ...state,
          isLoading: false,
          orders: updatedOrders
        };
      }
      return { ...state, orders: [...state.orders, payload], isLoading: false };
    case OrderActions.RequestOrdersByMonthAndDay:
      return { ...state, isLoadingByMonthAndDay: true };
    case OrderActions.ReceiveOrdersByMonthAndDay:
      return { ...state, sellerOrdersByMonthAndDay: payload, isLoadingByMonthAndDay: false };
    default:
      return state;
  }
};

export default reducer;
