import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

const handleGenerateOrdersExcel = async (data) => {
    // Crear un nuevo libro de trabajo
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Listado de Órdenes');

    worksheet.columns = [
        { header: 'ID Red Pine', key: 'dateInMilliseconds', width: 15 },
        { header: 'Laudus ID', key: 'laudusId', width: 15 },
        { header: 'Cliente', key: 'customerName', width: 25 },
        { header: 'Fecha', key: 'date', width: 15 },
        { header: 'Total Neto', key: 'discountedNetTotal', width: 15 },
        { header: 'Ruta', key: 'routeName', width: 25 },
        { header: 'Picker', key: 'pickerName', width: 25 },
        { header: 'Fecha de Asignación', key: 'pickerAssignDate', width: 20 },
        { header: 'Hora de Asignación', key: 'pickerAssignTime', width: 15 },
        { header: 'Fecha de Finalización', key: 'pickerFinishDate', width: 20 },
        { header: 'Hora de Finalización', key: 'pickerFinishTime', width: 15 },
    ];

    data.forEach((order) => {
        const assignDate = order.pickerAssignDate ? new Date(order.pickerAssignDate) : null;
        const finishDate = order.pickerFinishDate ? new Date(order.pickerFinishDate) : null;

        worksheet.addRow({
            dateInMilliseconds: order.dateInMilliseconds,
            laudusId: order.laudusId,
            customerName: order.customer.name,
            date: new Date(order.date).toLocaleDateString(),
            discountedNetTotal: order.discountedNetTotal,
            routeName: order.routeName,
            pickerName: order.pickerName,
            pickerAssignDate: assignDate ? assignDate.toLocaleDateString('es-ES') : 'No asignada',
            pickerAssignTime: assignDate ? assignDate.toLocaleTimeString('es-ES') : 'No asignada',
            pickerFinishDate: finishDate ? finishDate.toLocaleDateString('es-ES') : 'No asignada',
            pickerFinishTime: finishDate ? finishDate.toLocaleTimeString('es-ES') : 'No asignada',
        });
    });

    // Generar archivo Excel y guardarlo
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, 'Listado_Ordenes.xlsx');
};

export default handleGenerateOrdersExcel;
